import React from 'react';
import Footer from '../Footer';

class Terms extends React.Component {
  render() {
    return (
        <section className="content-section">
          <div className="container">
            <div>Terms & conditions</div>
            <div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut in erat vitae tellus congue cursus. Praesent pellentesque dui et massa tempus feugiat. Nullam elementum et odio sit amet consectetur. Morbi ultrices felis eu nisi bibendum, non pulvinar libero ultricies. Sed hendrerit fermentum ex eu aliquam. Fusce non turpis vel lectus pellentesque fermentum id non ipsum. Curabitur vestibulum tempus arcu eget tempus.</p>
                <p>Fusce blandit interdum aliquam. Donec volutpat tellus enim. Maecenas ac rutrum orci. Mauris elementum, sapien nec faucibus auctor, lectus tellus faucibus dui, et maximus diam mi vitae enim. Quisque ultricies rutrum lacus a auctor. Aliquam odio metus, consectetur non ipsum et, varius iaculis enim. Donec dignissim enim vitae turpis luctus varius. Aenean a quam feugiat, scelerisque neque at, sagittis felis. Vivamus elementum urna ut ante mattis, et efficitur augue semper. Nulla facilisi. In hac habitasse platea dictumst. Mauris viverra neque quis tellus commodo blandit. Suspendisse congue turpis purus, eget venenatis orci tempor eget. Aenean erat mauris, pulvinar eget justo eget, suscipit consequat metus.</p>
                <div>H3 title</div>
                <p>Nam faucibus aliquet mauris sit amet feugiat. Sed feugiat orci in sem tristique ornare. Nullam sed pretium neque, quis tincidunt felis. Cras elementum nec augue nec mollis. Nullam vel ex ut magna porttitor pretium vitae in metus. Duis pretium arcu non pretium rutrum. Vestibulum at ante maximus, tristique est eget, congue sem. Maecenas nibh erat, vestibulum id faucibus eget, mollis id libero. Praesent diam turpis, dapibus in dui eget, link text malesuada dapibus orci. Morbi a eleifend massa.</p>
                <p>Nullam eget nunc enim. Integer laoreet quis felis vitae dapibus. Aliquam eu ullamcorper lorem. Morbi posuere, orci non dignissim rhoncus, orci sem luctus velit, ut aliquam urna elit id ante. Vestibulum vel eros sit amet lorem malesuada tempor. Nunc sed sem id augue vestibulum posuere. Proin nec euismod quam. Maecenas et semper eros. Vestibulum dapibus purus sed arcu sagittis, id laoreet dui malesuada. Mauris at magna vitae ante tristique ullamcorper nec nec ex. Ut sed leo sodales ex feugiat euismod. Aliquam erat volutpat. Proin eu dolor eget est tincidunt malesuada.</p>
                <p>Mauris est nunc, lobortis in quam id, venenatis eleifend nisl. Pellentesque id urna vulputate, tempor dui ut, sagittis risus. Quisque ac convallis ligula. Donec sollicitudin mattis lacus. Maecenas ultricies neque metus, sed elementum eros commodo sit amet. Sed pretium, purus non sodales placerat, velit tortor accumsan est, sit amet malesuada risus eros lacinia augue. Curabitur felis justo, accumsan eu mi vitae, varius tincidunt eros. Pellentesque eu ante quis ligula consequat aliquet.</p>
            </div>
          </div>
          <Footer />
        </section>        
    )
  }
}

export default Terms;