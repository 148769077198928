import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {Elements, StripeProvider} from 'react-stripe-elements';
import { changeApiProduct } from '../../../store/actions';
import CheckoutForm from './CheckoutForm/CheckoutForm';

class Checkout extends React.Component {

    componentDidMount() {
        const { changeApiProduct } = this.props;
        var productObj = new Object({
            product: this.props.currentState.currentPaperType.type,
            size: this.props.currentState.currentFormat.type,
            amount: 1,
            orientation: this.props.currentState.currentOrientation.type,
            pdf: 'https://static.printmotor.com/printmotor-plain-500x700.pdf'
        });
        var productArr = new Array();
        productArr[0] = productObj; 
        changeApiProduct(productArr);
    }   

  render() {

    var VAT = (this.props.currentState.totalPrice * 0.24).toFixed(2);
    var totalPriceNoVAT = (this.props.currentState.totalPrice - VAT).toFixed(2);

    return (
        <section className="shop-section">
            <div className="shop-section-columns">
                <div className="shop-section-left-col">
                    <div className="shop-section-left-col__title">Checkout</div>
                    <div className="shop-section-left-col__text">Choose your payment method below</div>
                    <div className="shop-total-table">
                        <div className="shop-total-table-row">
                            <div>{this.props.currentState.currentFormat.title} Poster</div>
                            <div>{totalPriceNoVAT}€</div>
                        </div>
                        <div className="shop-total-table-row">
                            <div>Express shipping</div>
                            <div>0€</div>
                        </div>   
                        <div className="shop-total-table-row">
                            <div>Taxes</div>
                            <div>{VAT}€</div>
                        </div>   
                        <div className="shop-total-table-row shop-total-table-row--total">
                            <div>Total</div>
                            <div>{this.props.currentState.totalPrice}€</div>
                        </div> 
                    </div>
                    <div className="shop-payment-methods">
                        <div className="shop-payment-method">Credit/Debit card</div>
                        <div style={{'display': 'none'}}>
                            <StripeProvider apiKey="pk_test_vBjPQmTW9cfvlUxPsxlt8eN100Vuv50qV8">
                                <div className="example">
                                <Elements>
                                    <CheckoutForm />
                                </Elements>
                                </div>
                            </StripeProvider>
                        </div>
                    </div>
                    <div className="powered-by">Powered by Stripe</div>
                </div>
                <div className="shop-section-right-col shop-section-right-col--upload">
                    <div className="dropzone">
                        <div className="dropzone__preview" style={{backgroundImage: `url(${this.props.currentState.file})`}}></div>
                    </div>
                </div>
            </div>
        </section>
    )
  }
}

const putStateToProps = (state) => {
    return {
        currentState: state.currentState,
        apiCall: state.apiCall
    };
}

const putActionsToProps = (dispatch) => {
    return {        
        changeApiProduct: bindActionCreators(changeApiProduct, dispatch)
    }
}
  
export default connect(putStateToProps, putActionsToProps)(Checkout);