import React from 'react';
import Footer from '../Footer';

class About extends React.Component {
  render() {
    return (
      <section className="content-section">
        <div className="container">
          <div>About</div>
        </div>
        <Footer />
      </section>
    )
  }
}

export default About;