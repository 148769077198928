import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { changeResolutionsValue, changeRgbValue, changeCropmarksValue, changeTermsValue } from '../../../../store/actions';

import './UploadCheckbox.css';

class UploadCheckbox extends React.Component {

    render() {
        const { changeResolutionsValue, changeRgbValue, changeCropmarksValue, changeTermsValue } = this.props
        return (
            <div className={"upload-checkbox " + ( (this.props.uploadCheckboxType === 'resolution' && this.props.currentState.fileChecks.resolution.value) || (this.props.uploadCheckboxType === 'rgb' && this.props.currentState.fileChecks.rgb.value) || (this.props.uploadCheckboxType === 'cropmarks' && this.props.currentState.fileChecks.cropmarks.value) || (this.props.uploadCheckboxType === 'terms' && this.props.currentState.fileChecks.terms.value) ? 'upload-checkbox--active' : '')} >                            
                <div className="upload-checkbox__help">?</div>
                <div className="upload-checkbox__title" onClick={(event) => { 
                    if ( this.props.uploadCheckboxType === 'resolution' ){
                        changeResolutionsValue(!this.props.currentState.fileChecks.resolution.value);
                    } else if ( this.props.uploadCheckboxType === 'rgb' ){
                        changeRgbValue(!this.props.currentState.fileChecks.rgb.value);
                    } else if ( this.props.uploadCheckboxType === 'cropmarks' ){
                        changeCropmarksValue(!this.props.currentState.fileChecks.cropmarks.value);
                    } else if ( this.props.uploadCheckboxType === 'terms' ){
                        changeTermsValue(!this.props.currentState.fileChecks.terms.value);
                    }        
                }}
                className="upload-checkbox__title"><div className="upload-checkbox__radio"></div>{this.props.currentState.fileChecks[this.props.uploadCheckboxType].title}</div>
                <div className="upload-checkbox__subtitle">
                    { this.props.uploadCheckboxType === 'resolution' ? this.props.currentState.currentFormat.title + ' posters require minimum of 300dpi resolution and the artwork has to be in 1:1 scale' : null }
                    { this.props.uploadCheckboxType === 'rgb' ? 'Our posters are printed in RGB color space so please make sure you’re not uploading your artwork in CMYK color space' : null }
                    { this.props.uploadCheckboxType === 'cropmarks' ? this.props.currentState.currentFormat.title + ' posters require minimum of 300dpi resolution and the artwork has to be in 1:1 scale' : null }
                    { this.props.uploadCheckboxType === 'terms' ? <div>Read our <Link to="/terms" target="_blank">terms & conditions</Link> for our service.</div> : null }
                </div>
            </div>
        )
    }
}

const putStateToProps = (state) => {
    return {
        currentState: state.currentState,
        currentFormat: state.currentFormat
    };
}

const putActionsToProps = (dispatch) => {
    return {        
        changeResolutionsValue: bindActionCreators(changeResolutionsValue, dispatch),
        changeRgbValue: bindActionCreators(changeRgbValue, dispatch),
        changeCropmarksValue: bindActionCreators(changeCropmarksValue, dispatch),
        changeTermsValue: bindActionCreators(changeTermsValue, dispatch)
    };
}
  
export default connect(putStateToProps, putActionsToProps)(UploadCheckbox);