import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { changePrice, changeCurrentFormat, changeCurrentPaperType, changeCurrentOrientation } from '../../../../store/actions';

import './Dropdown.css';

class Dropdown extends React.Component {

    componentDidMount() {
    }

    render() {
        var dropdownType = this.props.dropdownType;
        const { changePrice, changeCurrentFormat, changeCurrentPaperType, changeCurrentOrientation } = this.props

        return (
            <div className="dropdown" data-type={dropdownType}>
                <div className="dropdown-value">
                    <div className="dropdown-options-item__title">
                        {this.props.currentState[this.props.current].title}
                    </div>
                    <div className="dropdown-options-item__subtitle">
                        {this.props.currentState[this.props.current].subtitle}
                    </div>
                    <div className="dropdown-options-item__price">
                        {this.props.currentState[this.props.current].pricePrefix}{this.props.currentState[this.props.current].price}{this.props.currentState[this.props.current].currency}
                    </div>
                </div>
                <div className="dropdown-options">
                    {this.props.productVariations[dropdownType].map((item, index) =>
                        <div key={index} 
                        onClick={(event) => {
                            if ( dropdownType === 'formats' ){
                                changeCurrentFormat({
                                    title: item.title,
                                    subtitle: item.subtitle,
                                    pricePrefix: item.pricePrefix,   
                                    price: item.price,
                                    currency: item.currency,
                                    type: item.type 
                                });
                                changePrice(item.price + this.props.currentState.currentPaperType.price + this.props.currentState.currentOrientation.price);
                            } else if ( dropdownType === 'paperTypes' ){
                                changeCurrentPaperType({
                                    title: item.title,
                                    subtitle: item.subtitle,
                                    pricePrefix: item.pricePrefix,   
                                    price: item.price,
                                    currency: item.currency,
                                    type: item.type    
                                }); 
                                changePrice(item.price + this.props.currentState.currentFormat.price + this.props.currentState.currentOrientation.price);
                            } else if ( dropdownType === 'orientations' ){
                                changeCurrentOrientation({
                                    title: item.title,
                                    subtitle: item.subtitle,
                                    pricePrefix: item.pricePrefix,   
                                    price: item.price,
                                    currency: item.currency,
                                    type: item.type     
                                });
                                changePrice(item.price + this.props.currentState.currentFormat.price + this.props.currentState.currentPaperType.price);
                            }
                        }} 
                        className={"dropdown-options-item " + (item.title === this.props.currentState.currentFormat.title || item.title === this.props.currentState.currentPaperType.title || item.title === this.props.currentState.currentOrientation.title ? 'dropdown-options-item--active' : '')}
                        >
                            <div className="dropdown-options-item__radio"></div>
                            <div className="dropdown-options-item__title">{item.title}</div>
                            <div className="dropdown-options-item__subtitle">{item.subtitle}</div>
                            <div className="dropdown-options-item__price">{item.pricePrefix}{item.price}{item.currency}</div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

const putStateToProps = (state) => {
    return {
        productVariations: state.productVariations,
        currentState: state.currentState
    };
}

const putActionsToProps = (dispatch) => {
    return {        
        changePrice: bindActionCreators(changePrice, dispatch),
        changeCurrentFormat: bindActionCreators(changeCurrentFormat, dispatch),
        changeCurrentPaperType: bindActionCreators(changeCurrentPaperType, dispatch),
        changeCurrentOrientation: bindActionCreators(changeCurrentOrientation, dispatch),
    }
}
  
export default connect(putStateToProps, putActionsToProps)(Dropdown);