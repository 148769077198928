import React from 'react';
import Footer from '../Footer';

class Home extends React.Component {
  render() {
    return (
      <div>
        <section className="hero-section">
          <div className="container">
            <div className="hero-section__h1">Get your artwork prints with few clicks</div>
            <div className="hero-section__text">High quality starting at 14,99€, free express shipping in Europe.</div>
            <div className="hero-section__btn-wr"><a className="btn btn-red" href="/">Order your print</a></div>
          </div>
        </section>
        <section className="process-section">
          <div className="container container-big process-section-container">
            <div className="process-item">
              <div className="process-item__title">Order your print</div>
              <div className="process-item__text">High quality starting at 14,99€, free express shipping in Europe.</div>
            </div>
            <div className="process-item">
              <div className="process-item__title">Upload art</div>
              <div className="process-item__text">High quality starting at 14,99€, free express shipping in Europe.</div>
            </div>
            <div className="process-item">
              <div className="process-item__title">One click payment</div>
              <div className="process-item__text">We support Apple pay, Google pay, creadit card, Alipay and Paypal</div>
            </div>  
          </div>      
        </section>
        <section className="benefits-section">
          <div className="container container-big benefits-section-container">
            <div className="col-left">
              <div className="benefit-item">
                <div className="benefit-item__title">Vivid colours that pop</div>
                <div className="benefit-item__text">High quality starting at 14,99€, free express shipping in Europe.</div>
                <div className="benefit-item__image"></div>
              </div>
              <div className="benefit-item">
                <div className="benefit-item__title">From designers for designers</div>
                <div className="benefit-item__text">High quality starting at 14,99€, free express shipping in Europe.</div>
                <div className="benefit-item__image"></div>
              </div>
            </div>
            <div className="col-right">
              <div className="benefit-item">
                <div className="benefit-item__title">Fast. Free. Shipping.</div>
                <div className="benefit-item__text">High quality starting at 14,99€, free express shipping in Europe.</div>
                <div className="benefit-item__image"></div>
              </div>
              <div className="benefit-item">
                <div className="benefit-item__title">Signup for our newsletter for discounts</div>
                <div className="benefit-item__text">High quality starting at 14,99€, free express shipping in Europe.</div>
                <div className="sign-up-form">
                  <input type="text" />
                  <button className="btn btn-red">Signup</button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    )
  }
}

export default Home;