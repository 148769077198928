import React, {useEffect, useState} from 'react';
import { bindActionCreators } from 'redux';
import {useDropzone} from 'react-dropzone';
import { connect } from 'react-redux';
import { changeFile } from '../../../../store/actions';

function DragAndDrop(props) {
  
  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      props.changeFile(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const [files, setFiles] = useState([]);
  const {getRootProps, getInputProps} = useDropzone({
    multiple: false,
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
      getBase64(acceptedFiles[0])
    }
  });

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <div {...getRootProps({className: 'dropzone'})} >
      <input {...getInputProps()} />

      { props.currentState.file.length > 0 ? <div className="dropzone__preview" style={{backgroundImage: `url(${props.currentState.file})`}}></div> : <div className="dropzone__text">Drag n drop here</div> }
    </div>
  );
}

const putStateToProps = (state) => {
  return {
      currentState: state.currentState
  };
}

const putActionsToProps = (dispatch) => {
  return {        
    changeFile: bindActionCreators(changeFile, dispatch)
  }
}

export default connect(putStateToProps, putActionsToProps)(DragAndDrop);