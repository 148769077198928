import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Header from './components/Header';
import Home from './components/Home/Home';
import ChoosePaper from './components/Shop/ChoosePaper/ChoosePaper';
import About from './components/About/About';
import Faq from './components/Faq/Faq';
import Terms from './components/Terms/Terms';
import Upload from './components/Shop/Upload/Upload';
import Checkout from './components/Shop/Checkout/Checkout';

import './App.css';

class App extends React.Component {
  render() {
    return (
      <div className="App">
          <Header/>
          <Route exact path="/" component={Home} />
          <Route path="/choose-paper" component={ChoosePaper} />    
          <Route path="/about-us" component={About} /> 
          <Route path="/faq" component={Faq} /> 
          <Route path="/terms" component={Terms} /> 
          <Route path="/upload-file" render={() => (
            this.props.currentState.currentFormat.price !== '' && this.props.currentState.currentPaperType.price !== '' && this.props.currentState.currentOrientation.price !== '' ? (
              <Upload/>
            ) : (
              <Redirect to="/choose-paper"/>
            )
          )}/>
          <Route path="/checkout" render={() => (
            this.props.currentState.fileChecks.resolution.value  && this.props.currentState.fileChecks.rgb.value && this.props.currentState.fileChecks.cropmarks.value && this.props.currentState.fileChecks.terms.value ? (
              <Checkout/> 
            ) : (
              <Redirect to="/upload-file"/>
            )
          )}/>
      </div>
    );
  }
}

const putStateToProps = (state) => {
  return {
    currentState: state.currentState
  };
}

export default connect(putStateToProps)(App);