import React from 'react';

class Footer extends React.Component {
    render() {
        return(
            <footer className="footer-section">
                <div className="container container-big">
                    <div className="footer-header">Get in touch</div>
                    <div className="contact-columns">
                        <div className="contact-col">
                            <div className="contact-col__h3">Visit us</div>
                            <div>Pixeleight Oy<br />
                            Pietarinkatu 11 00140 Helsinki</div>
                        </div>
                        <div className="contact-col">
                            <div className="contact-col__h3">Contact us</div>
                            <a href="/">hello@px8.fi</a><br />
                            <a href="/">+358406578779</a>
                        </div>
                        <div className="contact-col">
                            <div className="contact-col__h3">Follow us</div>
                            <a href="/">Dribbble</a><br />
                            <a href="/">Instagram</a><br />
                            <a href="/">Behance</a>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
  
export default Footer;
