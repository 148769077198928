import {ACTION_CHANGE_FILE, ACTION_CHANGE_PRICE, ACTION_CHANGE_CURRENT_FORMAT, ACTION_CHANGE_CURRENT_PAPERTYPE, ACTION_CHANGE_CURRENT_ORIENTATION, ACTION_CHANGE_CURRENT_CHECKBOX_RESOLUTION, ACTION_CHANGE_CURRENT_CHECKBOX_RGB, ACTION_CHANGE_CURRENT_CHECKBOX_CROPMARKS, ACTION_CHANGE_CURRENT_CHECKBOX_TERMS, ACTION_CHANGE_API_PRODUCT} from '../index';

export const changeFile = (value) => {
    return {
        type: ACTION_CHANGE_FILE,
        payload: value
    }
}

export const changePrice = (value) => {
    return {
        type: ACTION_CHANGE_PRICE,
        payload: value
    }
}

export const changeCurrentFormat = (value) => {
    return {
        type: ACTION_CHANGE_CURRENT_FORMAT,
        payload: value
    }
}

export const changeCurrentPaperType = (value) => {
    return {
        type: ACTION_CHANGE_CURRENT_PAPERTYPE,
        payload: value
    }
}

export const changeCurrentOrientation = (value) => {
    return {
        type: ACTION_CHANGE_CURRENT_ORIENTATION,
        payload: value
    }
}

export const changeResolutionsValue = (value) => {
    return {
        type: ACTION_CHANGE_CURRENT_CHECKBOX_RESOLUTION,
        payload: value
    }
}

export const changeRgbValue = (value) => {
    return {
        type: ACTION_CHANGE_CURRENT_CHECKBOX_RGB,
        payload: value
    }
}

export const changeCropmarksValue = (value) => {
    return {
        type: ACTION_CHANGE_CURRENT_CHECKBOX_CROPMARKS,
        payload: value
    }
}

export const changeTermsValue = (value) => {
    return {
        type: ACTION_CHANGE_CURRENT_CHECKBOX_TERMS,
        payload: value
    }
}

export const changeApiProduct = (value) => {
    return {
        type: ACTION_CHANGE_API_PRODUCT,
        payload: value
    }
}