import React from 'react';
import { Link, NavLink } from 'react-router-dom';

class Header extends React.Component {

    componentDidMount() {
        var burger = document.querySelector(".burger-mob");
        var html = document.querySelector("html");
        var navLinks = document.querySelectorAll(".header-menu__a");
    
        burger.addEventListener('click', function(event){
            event.preventDefault();
            this.classList.toggle('active');
            html.classList.toggle('menu-active');
        });

        for (var i = 0; i < navLinks.length; i++){
            navLinks[i].addEventListener('click', function(event){
                burger.classList.toggle('active');
                html.classList.toggle('menu-active');
            });
        }

    }

    render() {
        return(
            <header className="header-section">
                <Link className="header-logo" to="/">Konstverk.</Link>
                <a href="#" id="burger" className="burger-mob"><span></span></a>   
                <section className="header-menu-section">
                    <div className="header-menu-section-wr">
                        <div className="container container-big header-menu-section__container">
                            <div>
                                <ul className="header-menu">
                                    <li className="header-menu__li">
                                        <NavLink exact to="/" className="header-menu__a" activeClassName="active">Home</NavLink>
                                    </li>
                                    <li className="header-menu__li">
                                        <NavLink to="/choose-paper" className="header-menu__a" activeClassName="active">Shop</NavLink>
                                    </li>
                                    <li className="header-menu__li">
                                        <NavLink to="/about-us" className="header-menu__a" activeClassName="active">About us</NavLink>
                                    </li>
                                    <li className="header-menu__li">
                                        <NavLink to="/faq" className="header-menu__a" activeClassName="active">Faq</NavLink>
                                    </li> 
                                </ul>
                            </div>
                        </div>
                        <div className="container container-big header-menu-section__container">
                            <div className="contact-columns contact-columns--header">
                                <div className="contact-col">
                                    <div className="contact-col__h3">Visit us</div>
                                    <div>Pixeleight Oy<br />
                                    Pietarinkatu 11 00140 Helsinki</div>
                                </div>
                                <div className="contact-col">
                                    <div className="contact-col__h3">Contact us</div>
                                    <ul className="contact-col__ul">
                                        <li><a href="">hello@px8.fi</a></li>
                                        <li><a href="">+358406578779</a></li>
                                    </ul>
                                </div>
                                <div className="contact-col">
                                    <div className="contact-col__h3">Follow us</div>
                                    <ul className="contact-col__ul">
                                        <li><a href="">Dribbble</a></li>
                                        <li><a href="">Instagram</a></li>
                                        <li><a href="">Behance</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </header>
        )
    }
}

export default Header;