import React from 'react';
import { Link } from 'react-router-dom';
import Dropdown from './Dropdown/Dropdown';
import { connect } from 'react-redux';

class Shop extends React.Component {

  componentDidMount() {
    var dropdowns = document.querySelectorAll(".dropdown");

    for (var i = 0; i < dropdowns.length; i++){
        dropdowns[i].querySelector(".dropdown-value").addEventListener('click', function(event){
            this.parentElement.classList.toggle('active');
            return false;
        });
    }
  }

  render() {

    return (
      <section className="shop-section">
          <div className="shop-section-columns">
              <div className="shop-section-left-col">
                  <div className="shop-section-left-col__title">Choose paper</div>
                  <div className="shop-section-left-col__text">Choose the paper and size of your print. After this you can upload your art.</div>

                  <Dropdown dropdownType="formats" current="currentFormat" />
                  <Dropdown dropdownType="paperTypes" current="currentPaperType" />
                  <Dropdown dropdownType="orientations" current="currentOrientation" />

              </div>
              <div className="shop-section-right-col">
                  <div className="poster-sample-cols">
                    <div className="poster-sample-col">
                      <div className="poster-sample poster-sample--a1">
                        <div className="poster-sample__size">
                          700x1000<br />mm
                        </div>
                        <div className="poster-sample__title">A1</div>
                      </div>
                    </div>
                    <div className="poster-sample-col">
                      <div className="poster-sample poster-sample--a2">
                        <div className="poster-sample__size">
                          500x700<br />mm
                        </div>
                        <div className="poster-sample__title">A2</div>
                      </div>
                      <div className="poster-sample-desc"><span className="poster-sample-desc__price">48€</span> incl. 24% tax</div>
                    </div>
                    <div className="poster-sample-col">
                      <div className="poster-sample poster-sample--a3">
                        <div className="poster-sample__size">
                          300x400<br />mm
                        </div>
                        <div className="poster-sample__title">A3</div>
                      </div>
                      <div className="poster-sample poster-sample--a4">
                        <div className="poster-sample__size">
                          210x297<br />mm
                        </div>
                        <div className="poster-sample__title poster-sample__title--a4">A4</div>
                      </div>
                      <div className="poster-sample poster-sample--a5">
                        <div className="poster-sample__size">
                          148x210<br />mm
                        </div>
                        <div className="poster-sample__title poster-sample__title--a5">A5</div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
          <div className="footer-total-section">
              <div className="footer-total">
                  <div className="footer-total__title">Total: {this.props.currentState.totalPrice}€</div>
                  <div className="footer-total__text">Including free express shipping</div>
              </div>
              <div className="footer-total-btns">
                { this.props.currentState.currentFormat.price !== '' && this.props.currentState.currentPaperType.price !== '' && this.props.currentState.currentOrientation.price !== '' ? <Link to="/upload-file" className="btn btn-red" >Next step</Link> : <div className="btn btn-red disabled">Next step</div>}
              </div>
          </div>
      </section>
    )
  }
}

const putStateToProps = (state) => {
    return {
        currentState: state.currentState
    };
  }
  
  export default connect(putStateToProps)(Shop);