import {ACTION_CHANGE_FILE, ACTION_CHANGE_PRICE, ACTION_CHANGE_CURRENT_FORMAT, ACTION_CHANGE_CURRENT_PAPERTYPE, ACTION_CHANGE_CURRENT_ORIENTATION, ACTION_CHANGE_CURRENT_CHECKBOX_RESOLUTION, ACTION_CHANGE_CURRENT_CHECKBOX_RGB, ACTION_CHANGE_CURRENT_CHECKBOX_CROPMARKS, ACTION_CHANGE_CURRENT_CHECKBOX_TERMS, ACTION_CHANGE_API_PRODUCT} from '../index';

const initialState = {
    productVariations: {
        formats: [
            {
                title: 'A1',
                subtitle: '700x1000mm',
                pricePrefix: '',   
                price: 5,
                currency: '€',
                type: 'A1' 
            },
            {
                title: 'A2',
                subtitle: '500x700mm',
                pricePrefix: '',   
                price: 4,
                currency: '€',
                type: 'A2'    
            },
            {
                title: 'A3',
                subtitle: '300x400mm',
                pricePrefix: '',   
                price: 3,
                currency: '€',
                type: 'A3'    
            },
            {
                title: 'A4',
                subtitle: '210x297mm',
                pricePrefix: '',   
                price: 2,
                currency: '€',
                type: 'A4'    
            },
            {
                title: 'A5',
                subtitle: '148x210mm',
                pricePrefix: '',   
                price: 1,
                currency: '€',
                type: 'A5'   
            }
        ],
        paperTypes: [
            {
                title: 'Matt paper',
                subtitle: '',
                pricePrefix: '+',   
                price: 0,
                currency: '€',
                type: 'matt-poster'    
            },
            {
                title: 'Semi-glossy paper',
                subtitle: '',
                pricePrefix: '+', 
                price: 1,
                currency: '€',
                type: 'glossy-poster'      
            }
        ],
        orientations: [
            {
                title: 'Portrait',
                subtitle: '',
                pricePrefix: '+', 
                price: 0,
                currency: '€',
                type: 'portrait'    
            },
            {
                title: 'Landscape',
                subtitle: '',
                pricePrefix: '+', 
                price: 1,
                currency: '€',
                type: 'landscape'     
            }
        ]
    },
    currentState: {
        totalPrice: 0,
        file: '',
        currentFormat: {
            title: 'Choose size',
            subtitle: '',
            pricePrefix: '',   
            price: '',
            currency: '',
            type: ''   
        },
        currentPaperType: {
            title: 'Choose paper',
            subtitle: '',
            pricePrefix: '',   
            price: '',
            currency: '',
            type: ''      
        },
        currentOrientation: {
            title: 'Choose orientation',
            subtitle: '',
            pricePrefix: '',   
            price: '',
            currency: '',
            type: ''      
        },
        fileChecks: {
            resolution: {
                title: 'I’ve checked the resolution',
                value: false
            },
            rgb: {
                title: 'I’ve checked that the file is in RGB color',
                value: false
            },
            cropmarks: {
                title: 'I’ve checked that i have no bleeds or cropmarks',
                value: false
            },
            terms: {
                title: 'I’ve read and agree with the terms',
                value: false
            }
        }
    },
    apiCall: {
        "orderer": {
            "firstName": "Firstname",
            "lastName": "Lastname",
            "emailAddress": "my.name@mydomain.com",
            "phone": "+358301234567",
        },
        "address": {
            "address": "Street address",
            "address2": "extended street address",
            "postalArea": "Stockholm",
            "postalCode": "01234",
            "countryIso2": "SE"
        },
        "products": ''
    }
}

export const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_CHANGE_FILE:
            return { 
                ...state,
                currentState: {
                    ...state.currentState, 
                    file: action.payload
                } 
            };
        case ACTION_CHANGE_PRICE:
            return { 
                ...state,
                currentState: {
                    ...state.currentState, 
                    totalPrice: action.payload
                } 
            };
        case ACTION_CHANGE_CURRENT_FORMAT:
            return { 
                ...state,
                currentState: {
                    ...state.currentState, 
                    currentFormat: action.payload
                } 
            };
        case ACTION_CHANGE_CURRENT_PAPERTYPE:
            return { 
                ...state,
                currentState: {
                    ...state.currentState, 
                    currentPaperType: action.payload
                } 
            };
        case ACTION_CHANGE_CURRENT_ORIENTATION:
            return { 
                ...state,
                currentState: {
                    ...state.currentState, 
                    currentOrientation: action.payload
                } 
            };
        case ACTION_CHANGE_CURRENT_CHECKBOX_RESOLUTION:
            return { 
                ...state,
                currentState: {
                    ...state.currentState, 
                    fileChecks: {
                        ...state.currentState.fileChecks,
                        resolution: {
                            ...state.currentState.fileChecks.resolution, 
                            value: action.payload
                        } 
                    }
                } 
            };
        case ACTION_CHANGE_CURRENT_CHECKBOX_RGB:
            return { 
                ...state,
                currentState: {
                    ...state.currentState, 
                    fileChecks: {
                        ...state.currentState.fileChecks,
                        rgb: {
                            ...state.currentState.fileChecks.rgb, 
                            value: action.payload
                        } 
                    }
                } 
            }; 
        case ACTION_CHANGE_CURRENT_CHECKBOX_CROPMARKS:
            return { 
                ...state,
                currentState: {
                    ...state.currentState, 
                    fileChecks: {
                        ...state.currentState.fileChecks,
                        cropmarks: {
                            ...state.currentState.fileChecks.cropmarks, 
                            value: action.payload
                        } 
                    }
                } 
            };  
        case ACTION_CHANGE_CURRENT_CHECKBOX_TERMS:
            return { 
                ...state,
                currentState: {
                    ...state.currentState, 
                    fileChecks: {
                        ...state.currentState.fileChecks,
                        terms: {
                            ...state.currentState.fileChecks.terms, 
                            value: action.payload
                        } 
                    }
                } 
            };   
        case ACTION_CHANGE_API_PRODUCT:
            return { 
                ...state,
                apiCall: {
                    ...state.apiCall, 
                    products: action.payload
                } 
            };      
        default:
    }
    return state;
};