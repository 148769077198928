import React, {Component} from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import { connect } from 'react-redux';

import './CheckoutForm.css';

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = { complete: false, error: false };
  }

  async submit(ev) {
    let {token} = await this.props.stripe.createToken({name: "Name"});
    let response = await fetch("/charge", {
      method: "POST",
      headers: {"Content-Type": "text/plain"},
      body: token.id
    });
    if (response.error) this.setState({error: true});
    else if (response.ok) this.setState({ complete: true, error: false });
  }

  render() {
    if (this.state.error) return <div>Invalid data</div>;
    else if (this.state.complete) return <h1>Purchase Complete</h1>;
    return (
      <div>
        <CardElement className="card-element" />
        <div className="btn btn-red place-order-btn" 
          onClick={this.submit
            /*
            (event) => {
              var apiCall = this.props.apiCall;
              if (this.state.complete) {
                (async () => {
                  const rawResponse = await fetch('https://api.printmotor.com/api/v1/orders', {
                      method: 'POST',
                      headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                          'X-Printmotor-Service': 'e792d50675fece46f534003dce46f889',
                          'Authorization': 'Basic S29uc3R2ZXJrdGVzdHM6RzZGczI4cEQ0eTJ1NEVaWg=='
                      },
                      body: JSON.stringify(apiCall)
                  });
                  const content = await rawResponse.json();
                  
                  console.log(content);
                  localStorage.clear();
                  })();
              }
          }
        */
        }>Place order</div>
      </div>
    );
  }
}

const putStateToProps = (state) => {
  return {
      currentState: state.currentState,
      apiCall: state.apiCall
  };
}

export default connect(putStateToProps)(injectStripe(CheckoutForm));