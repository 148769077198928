import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { rootReducer } from './store/reducer';
import { loadState, saveState } from './localStorage';
import throttle from 'lodash/throttle';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

export const ACTION_CHANGE_FILE = 'ACTION_CHANGE_FILE';
export const ACTION_CHANGE_PRICE = 'ACTION_CHANGE_PRICE';
export const ACTION_CHANGE_CURRENT_FORMAT = 'ACTION_CHANGE_CURRENT_FORMAT';
export const ACTION_CHANGE_CURRENT_PAPERTYPE = 'ACTION_CHANGE_CURRENT_PAPERTYPE';
export const ACTION_CHANGE_CURRENT_ORIENTATION = 'ACTION_CHANGE_CURRENT_ORIENTATION';
export const ACTION_CHANGE_CURRENT_CHECKBOX_RESOLUTION = 'ACTION_CHANGE_CURRENT_CHECKBOX_RESOLUTION';
export const ACTION_CHANGE_CURRENT_CHECKBOX_RGB = 'ACTION_CHANGE_CURRENT_CHECKBOX_RGB';
export const ACTION_CHANGE_CURRENT_CHECKBOX_CROPMARKS = 'ACTION_CHANGE_CURRENT_CHECKBOX_CROPMARKS';
export const ACTION_CHANGE_CURRENT_CHECKBOX_TERMS = 'ACTION_CHANGE_CURRENT_CHECKBOX_TERMS';
export const ACTION_CHANGE_API_PRODUCT = 'ACTION_CHANGE_API_PRODUCT';

const persistedState = loadState();
const store = createStore(
    rootReducer,
    persistedState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

store.subscribe(throttle(() => {
    saveState(store.getState());
}, 1000));

// localStorage.clear();

ReactDOM.render(<Provider store={store}>
    <HashRouter >
        <App />
    </HashRouter >
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
