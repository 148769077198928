import React from 'react';
import { Link } from 'react-router-dom';
import UploadCheckbox from './UploadCheckbox/UploadCheckbox';
import { connect } from 'react-redux';
import DragAndDrop from './DragAndDrop/DragAndDrop';

class Upload extends React.Component {

  render() {
    return (
        <section className="shop-section">
            <div className="shop-section-columns">
                <div className="shop-section-left-col">
                    <div className="shop-section-left-col__title">Upload design</div>
                    <div className="shop-section-left-col__text">Choose the paper and size of your print. After this you can upload your art.</div>
                    <div className="upload-checkboxes">
                        <UploadCheckbox uploadCheckboxType="resolution" />
                        <UploadCheckbox uploadCheckboxType="rgb" />
                        <UploadCheckbox uploadCheckboxType="cropmarks" />
                        <UploadCheckbox uploadCheckboxType="terms" />
                    </div>
                </div>
                <div className="shop-section-right-col shop-section-right-col--upload">
                    <DragAndDrop />
                </div>
            </div>
            <div className="footer-total-section">
                <div className="footer-total">
                    <div className="footer-total__title">Total: {this.props.currentState.totalPrice}€</div>
                    <div className="footer-total__text">Including free express shipping</div>
                </div>
                <div className="footer-total-btns">
                    {this.props.currentState.fileChecks.resolution.value  && this.props.currentState.fileChecks.rgb.value && this.props.currentState.fileChecks.cropmarks.value && this.props.currentState.fileChecks.terms.value && (this.props.currentState.file.length > 0 ) ? <Link to="/checkout" className="btn btn-red">Checkout</Link> : <div className="btn btn-red disabled">Checkout</div>}
                </div>
            </div>
        </section>
    )
  }
}

const putStateToProps = (state) => {
    return {
        currentState: state.currentState
    };
  }
  
export default connect(putStateToProps)(Upload);